import React from "react";
import "./App.css";
import { IMAGES } from "./assets";

const App: React.FC = () => {
  return (
    <div className="container">
      <img src={IMAGES.lilikoi} alt="Lilikoi the Labrador" />
      <h1>Joseph Lubin finally got a dog - an adorable pup named Lilikoi!</h1>
      <p>
        She loves playing with blocks, eating passionfruit and being the cutest
        meme on the linea blockchain.
      </p>
      <h2>Contract Address: 0xca7d460c83678c358e8fc9b9629e8ac36d67c567</h2>
      <div className="social-links">
        <a
          href="https://twitter.com/Lilikoi_xyz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>{" "}
        |{" "}
        <a
          href="https://t.me/lili_koi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Telegram
        </a>{" "}
        | <a href="mailto:lilikoixyz@proton.me">Email</a>
      </div>
      <p>See us on:</p>
      <div className="listings">
        <a 
          href="https://www.dextools.io/app/en/linea/pair-explorer/0x29df8a5d68937ab016a354a45510208d6e75c5cc?t=1711952767710"
          target="_blank"
          rel="noopener noreferrer">
            <img src={IMAGES.dextools} alt="Dextools"></img>
        </a>
      </div>
      <p>Made with ❤️ in 🇮🇳</p>
      <div className="passionfruit-animation">
        {Array.from({ length: 10 }).map((_, index) => (
          <img
            key={index}
            src={IMAGES.passionfruit}
            className="falling-passionfruit"
            alt="Passionfruit"
            style={{
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 5 + 5}s`, // Random duration between 5 and 10 seconds
              animationDelay: `${Math.random() * 5}s`, // Random delay to desynchronize the animations
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default App;
